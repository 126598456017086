import "./selectedTicket.scss";
import Button from "react-bootstrap/Button";
import DetailsButton from "../../DetailsButton/DetailsButton";
import { formatCurrency } from "../../../../../utilities/helpers";
import { QuickpickBadge } from "../../../NewSeatedPurchaseWrapper/NewSeatedPurchaseContainer/TicketSelectionPanel/ListingsContainer/TicketGroup/TicketGroup";

export default function SelectedTicket({
  ticketGroup,
  toggleView,
  unselectSeat
}) {
  const { price } = ticketGroup;
  let offerName;
  let inventoryType;

  if (!Boolean(ticketGroup?.listing)) {
    const offer = ticketGroup?.offer || ticketGroup?.package;
    offerName = offer?.name;
    inventoryType = offer?.inventoryType;
  }

  return (
    <div className='vjx-yy'>
      <Button variant='outline-light' className='vjx-btn'>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          onClick={() => unselectSeat(ticketGroup?.seatId, ticketGroup)}
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.97652 3.97554C4.21083 3.74123 4.59073 3.74123 4.82505 3.97554L8.00078 7.15128L11.1765 3.97554C11.4108 3.74123 11.7907 3.74123 12.025 3.97554C12.2594 4.20986 12.2594 4.58975 12.025 4.82407L8.84931 7.9998L12.025 11.1755C12.2594 11.4099 12.2594 11.7898 12.025 12.0241C11.7907 12.2584 11.4108 12.2584 11.1765 12.0241L8.00078 8.84833L4.82505 12.0241C4.59073 12.2584 4.21083 12.2584 3.97652 12.0241C3.7422 11.7898 3.7422 11.4099 3.97652 11.1755L7.15225 7.9998L3.97652 4.82407C3.7422 4.58975 3.7422 4.20986 3.97652 3.97554Z'
            fill='#23262F'
          />
        </svg>
      </Button>
      <div className='vjx-yt'>
        <div className='vjx-ytt'>
          {ticketGroup.GA ? (
            <span className='vjx-pos1'>{ticketGroup?.sectionName}</span>
          ) : (
            <>
              <Position segment={"SEC"} position={ticketGroup?.sectionNumber} />
              <Position segment={"ROW"} position={ticketGroup?.rowNumber} />
              <Position segment={"SEAT"} position={ticketGroup?.seatNumber} />
            </>
          )}
        </div>
        <div className='vjx-ytr'>
          <span className='vjx-ytp'>{formatCurrency(price)}</span>
          <span className='vjx-yti'>Incl. Taxes & Fees</span>
        </div>
      </div>
      <div className='vjx-yb'>
        <QuickpickBadge
          isListing={Boolean(ticketGroup?.listing)}
          listing={ticketGroup?.listing}
          inventoryType={inventoryType}
          offerName={offerName}
        />
        <div className='vjx-ybb'>
          {/* IF an accessible seat */}
          {false && (
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6.78712 3.28289C7.65292 3.28289 8.34952 2.57909 8.34952 1.73129C8.34952 0.870888 7.65292 0.179688 6.78712 0.179688C5.92492 0.179688 5.22472 0.870888 5.22472 1.73129C5.22472 2.57909 5.92492 3.28289 6.78712 3.28289ZM15.9563 13.8975L13.2923 9.33089C13.1267 9.05549 12.8369 8.90069 12.5363 8.89169H8.62672L8.58712 7.51829H11.4257C11.8127 7.49489 12.1313 7.20329 12.1313 6.80909C12.1313 6.42029 11.8217 6.11789 11.4257 6.10529H8.50792L8.40712 4.97489C8.34952 4.16129 7.64392 3.52049 6.81592 3.56549C5.98072 3.61229 5.35072 4.32509 5.39032 5.14949L5.66572 9.85829C5.73412 10.6845 6.42892 11.2695 7.25332 11.2695H12.3203L14.3903 14.8281C14.6387 15.2313 15.2129 15.3969 15.6449 15.1395C16.0715 14.8803 16.1993 14.3475 15.9563 13.8975ZM7.79152 16.5993C5.22832 16.5993 3.14932 14.5401 3.14932 11.9913C3.14932 10.5963 3.78652 9.35069 4.77832 8.50289L4.69192 7.06289C3.03232 8.09249 1.91992 9.90149 1.91992 11.9913C1.91992 15.2097 4.54612 17.8215 7.79152 17.8215C10.1801 17.8215 12.2213 16.3941 13.1393 14.3637L12.3149 12.9417C11.8721 15.0243 10.0217 16.5993 7.79152 16.5993Z'
                fill='#23262F'
              />
            </svg>
          )}
          <DetailsButton
            text='Details'
            onClick={() => toggleView(ticketGroup, ticketGroup?.seatId)}
          />
        </div>
      </div>
    </div>
  );
}

function Position({ segment, position }) {
  return (
    <div className='vjx-pos'>
      <span className='vjx-pos1'>{segment}</span>
      <span className='vjx-pos2'>{position}</span>
    </div>
  );
}
