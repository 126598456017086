import React from "react";

import { PackageSeatmapProvider } from "./PackageSeatmapProvider";

import { PackageDetails } from "./PackageDetails";

import "./viewPackageWrapper.scss";

export default function ViewPackageWrapper({ eventPackage }) {
    return (
        <PackageSeatmapProvider eventPackage={eventPackage}>
            <PackageDetails eventPackage={eventPackage} />
        </PackageSeatmapProvider>
    );
}
