import React from 'react';
import { useNavigate } from 'react-router-dom';

import { formatCurrency } from '../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { LoadingContainer } from '../../LoadingContainer';

import './lists.scss';

export default function PackagesList({ packages }) {
    const navigate = useNavigate()

    return (
        <>
            {packages?.length > 0 ? (
                <Stack as="ul">
                    <>
                        {packages.map((eventPackage, idx) => (
                            <Card body as="li" key={idx} className='card-xs card--light item'>
                                <Stack direction="horizontal" gap={3}>
                                    <Card.Img
                                        src={eventPackage?.image?.url}
                                        alt={`Cover art for ${eventPackage?.name} package`}
                                        width="100"
                                        height="100"
                                        className="event-image"
                                        style={{ width: '118px', height: '94px' }}
                                    />
                                    <div className="d-flex-column gap-2 align-items-start">
                                        <div>
                                            <span className="text-muted caption--uppercase">{eventPackage?.category.name}</span>
                                            <p className="normal normal-bold">{eventPackage?.name}</p>
                                        </div>
                                        <Button className="mt-1" onClick={() => navigate(`package/${eventPackage.uuid}`)}>From {formatCurrency(eventPackage.price)}</Button>
                                    </div>
                                </Stack>
                            </Card>
                        ))}
                    </>
                </Stack>
            ) : (
                <Stack className='align-items-center'>
                    <h1 className='fs-md'>No packages</h1>
                    <p className='subtitle'>This venue has no packages</p>
                </Stack>
            )}
            {/* <LoadingContainer /> */}
        </>
    );
}
