import React from 'react';
import { useNavigate } from 'react-router-dom';

import { usePackageSeatmap } from '../PackageSeatmapProvider';

import { formatCurrency } from '../../../utilities/helpers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { BackButton } from '../../BackButton';
import { PackageEvent } from '../../PackageEvent';

export default function PackageDetails({ eventPackage }) {

    const { showSeatmap } = usePackageSeatmap();

    const navigate = useNavigate()

    let count = 1;
    let options = [];

    while (count <= eventPackage?.maxQuantity) {
        options.push(count)
        count++
    }

    const handleGoBack = () => {
        navigate(`/venue/${eventPackage?.venue?.slug}`)
    }

    return (
        <Row id="view-package">
            <Col>
                <Card className='about-card card-xl card-xl--dark full-height'>
                    <Card.Body className="d-flex-column align-items-start gap-4">
                        <BackButton handleGoBack={handleGoBack} marginBottom={'0'} />
                        <div className='d-flex-column gap-4'>
                            <div className="card-img-container">
                                <Card.Img src={eventPackage?.image?.url} width="114" height="114" alt={`Cover art for ${eventPackage?.name} package`} />
                            </div>
                            <div className='d-flex-column gap-3'>
                                <Card.Subtitle as="h6">{eventPackage?.organization?.name}</Card.Subtitle>
                                <Card.Title as="h5" className='m-0'>{eventPackage?.name} </Card.Title>
                                <Card.Text className='location text-secondary'>{eventPackage?.venue?.name} </Card.Text>
                                {eventPackage?.description && (<Card.Text>{eventPackage?.description}</Card.Text>)}
                                {/* Below seems redundant from description */}
                                {/* <Card.Text><span className='pb-4 mt-2 d-block'>Package Benefits</span>
                                    <ul>
                                        <li>40 games of thrilling hockey action</li>
                                        <li>Priority seating with the best views of the ice</li>
                                        <li>Exclusive Ice Titans merchandise discounts</li>
                                        <li>Access to special fan events and meet-and-greets</li>
                                        <li>Dedicated customer support for package holders</li>
                                    </ul>
                                </Card.Text> */}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card body className='card-xl card-xl--dark full-height'>
                    <Stack gap={4} className='full-height'>
                        {/* <div className='seat-quantity align-items-center gap-4 justify-content-between'>
                            <Stack>
                                <Card.Title as="h5" className='card-title-sm'>Seat quantity</Card.Title>
                                <p className='small'>Select number of seats per event, you will be seated together.</p>
                            </Stack>
                            <Form.Select aria-label="Seat quantity">
                                {options && options.map((option, index) => <option value={option} key={index}>{option} Ticket</option>)}
                            </Form.Select>
                        </div> */}
                        <div className='events-list d-flex-column gap-4 justify-content-between flex-grow-1'>
                            <Card.Title as="h5" className='card-title-sm m-0'>Events list</Card.Title>
                            <Stack as="ul" gap={2}>
                                {eventPackage?.events?.map((event, index) => (
                                    <li key={index}>
                                        <PackageEvent event={event} venue={eventPackage?.venue} timezone={eventPackage?.timezone} />
                                    </li>
                                ))}
                            </Stack>
                        </div>
                        <div className='gap-2 d-flex-column flex-md-row align-items-md-center mt-auto justify-content-md-between'>
                            <div className='d-flex-column gap-1'>
                                <span className='normal'>Total from <span className='fw-semi-bold'>{formatCurrency(eventPackage?.price)}</span> </span>
                                <span className='small text-muted'>{eventPackage?.events?.length} events</span>
                            </div>
                            <Button size="lg" className='mt-0 btn-next' onClick={showSeatmap}>Choose your seats</Button>
                        </div>
                    </Stack>
                </Card>
            </Col>
        </Row>
    );
}
