import { useEffect, useState } from "react";

import { getSinglarOrPluralWord } from "../../../../utilities/helpers";

import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import { QuickpickBadge } from "../../NewSeatedPurchaseWrapper/NewSeatedPurchaseContainer/TicketSelectionPanel/ListingsContainer/TicketGroup/TicketGroup";

import placeholder from "../../../../assets/placeholder.png";

import "./buyingThis.scss";

export default function BuyingThis({
  cart,
  event,
  showBtn = false,
  handleClick,
}) {
  const [ticketGroups, setTicketGroups] = useState([]);
  useEffect(() => {
    if (cart?.ticket_groups && cart?.tickets) {
      const updatedGroups = cart.ticket_groups.map((ticketGroup) => {
        // find all tickets that belong to this group
        const groupTickets = cart.tickets.filter((ticket) =>
          ticketGroup.allSeatIds.includes(ticket.seatId)
        );

        return {
          ...ticketGroup,
          tickets: groupTickets,
        };
      });

      setTicketGroups(updatedGroups);
    }
  }, [cart, event]);

  if (!cart || !cart.tickets || cart.tickets.length === 0) {
    return null;
  }

  let getTicketImage = (sectionId) => {
    const images = event?.seatmap?.images;
    const match = images?.find((image) =>
      image.name.includes("0857108a-f4ec-4241-8862-fbd24750c8fe")
    ); // "sectionId"

    if (match?.url) {
      return match.url;
    } else {
      return placeholder;
    }
  };

  const getSeats = (seats) => {
    if (seats.length === 0) return "";
    seats.sort((a, b) => a - b);
    const ranges = [];
    let start = seats[0];
    let prev = seats[0];

    for (let i = 1; i <= seats.length; i++) {
      if (i === seats.length || seats[i] !== prev + 1) {
        ranges.push(start === prev ? start.toString() : `${start}-${prev}`);
        start = seats[i];
      }
      prev = seats[i];
    }

    // If there's only one range and it's a consecutive range (contains a hyphen)
    if (ranges.length === 1 && ranges[0].includes("-")) {
      return "Your seats are together 👥";
    }

    return `${getSinglarOrPluralWord(seats?.length, "Seat")}: ${ranges.join(
      ", "
    )}`;
  };

  if (cart?.package) {
    return (
      <div className='buying-this-container'>
        <SharedCard
          data={cart}
          getTicketImage={getTicketImage}
          showBtn={showBtn}
          handleClick={handleClick}
          getSeats={getSeats}
          isPackage={true}
        />
      </div>
    );
  }

  return (
    <div className='buying-this-container'>
      {ticketGroups.map((ticketGroup, i) => {
        return (
          <SharedCard
            key={i}
            data={ticketGroup}
            getTicketImage={getTicketImage}
            showBtn={showBtn}
            handleClick={handleClick}
            getSeats={getSeats}
            isPackage={false}
          />
        );
      })}
    </div>
  );
}

const SharedCard = ({
  getTicketImage,
  showBtn,
  handleClick,
  getSeats,
  data,
  isPackage
}) => {

  // User can only purchase season package seats from a single row/section at a time...
  const ticketInfo = isPackage ? {
    GA: data.ticket_groups[0].GA,
    sectionId: data.ticket_groups[0].sectionId,
    sectionNumber: data.ticket_groups[0].sectionNumber,
    rowNumber: data.ticket_groups[0].rowNumber,
    tickets: data.tickets.filter(
      (ticket, index, self) =>
        index === self.findIndex((t) => t.seatId === ticket.seatId)
    ),
    count: data.package.events.length,
    name: data.package?.name
  } : {
    GA: data.GA,
    sectionId: data.sectionId,
    sectionNumber: data.sectionNumber,
    rowNumber: data.rowNumber,
    tickets: data.tickets,
    count: data.tickets?.length,
    name: data.offer?.name
  };

  const badgeProps = isPackage ? {
    isListing: false,
    listing: undefined,
    inventoryType: "open",
    offerName: ticketInfo.name
  } : {
    isListing: Boolean(data.listing),
    listing: data.listing,
    inventoryType: data.offer?.inventoryType,
    offerName: ticketInfo.name
  };

  return (
    <Card className="card-xl card-xl--light buying-this">
      <Card.Body className="d-flex-column gap-2">
        <div className="d-flex col-divider">
          <div className="vjx-img-wrap">
            <img
              src={getTicketImage(ticketInfo.sectionId)}
              alt="Seat view for this ticket"
            />
          </div>
          <div className="ticket-info">
            <div className="col">
              <Stack direction="horizontal" gap={2} className="flex-wrap">
                <QuickpickBadge {...badgeProps} />
              </Stack>
              <Stack className="gap-md-2 align-items-start mt-2">
                <Card.Title
                  as="h5"
                  className={`ticket-loc ${ticketInfo.GA ? "ticket" : "seat"} ticket-loc`}
                >
                  {ticketInfo.GA
                    ? "General Admission"
                    : `Sec ${ticketInfo.sectionNumber} • Row ${ticketInfo.rowNumber}`}
                </Card.Title>

                <div className="tablet-desktop-only small">
                  {ticketInfo.GA ? (
                    <Card.Text>This is a standing area 🧑‍🤝‍🧑</Card.Text>
                  ) : (
                    <Card.Text>
                      {getSeats(ticketInfo.tickets.map((t) => Number(t.seatNumber)))}
                    </Card.Text>
                  )}
                </div>
              </Stack>
            </div>
            <div className="tablet-desktop-only">
              <div className="right-side">
                <div className="ticket-count-info">
                  <span className="ticket-count">{ticketInfo.count}</span>
                  <span className="ticket-text">
                    {getSinglarOrPluralWord(
                      ticketInfo.count,
                      isPackage ? "Event" : undefined
                    )}
                  </span>
                </div>
                {showBtn && (
                  <Button
                    variant="secondary"
                    onClick={handleClick}
                    className="mt-0"
                  >
                    Go to my wallet
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <Stack
          direction="horizontal"
          className="pt-2 justify-content-between align-items-center mobile-only small"
        >
          {ticketInfo.GA ? (
            <span>This is a standing area 🧑‍🤝‍🧑</span>
          ) : (
            <span>
              {getSeats(ticketInfo.tickets.map((t) => Number(t.seatNumber)))}
            </span>
          )}
          <span>
            {ticketInfo.count}{" "}
            <span>
              {getSinglarOrPluralWord(
                ticketInfo.count,
                isPackage ? "Event" : undefined
              )}
            </span>
          </span>
        </Stack>
      </Card.Body>
    </Card>
  );
};