import { useState } from "react";
import SheetBottom from "../../../../../../../components/SheetBottom/SheetBottom";
import YourSelection from "../../../../../../../components/YourSelection/YourSelection";
import TicketDetails from "../../../../../../../components/TicketDetails/TicketDetails";
import "./mapSheet.scss";

export default function MapSheet({
  canGoBack = false,
  goBack = undefined,
  totalCount,
  totalPrice,
  eventTicketLimit,
  checkout,
  checkingAvailability,
  selectedFromMap
}) {
  const [firstView, setFirstView] = useState(true);
  const [selectedObject, setSelectedObject] = useState(null);

  const toggleView = (seatInfo, seatId) => {
    setFirstView(!firstView);
    setSelectedObject({ seatId, ...seatInfo });
  };

  return (
    <div className='mapsheet'>
      <div className='mapsheet-inner'>
        <div className='content'>
          {firstView ? (
            <YourSelection
              toggleView={toggleView}
              canGoBack={canGoBack}
              goBack={goBack}
              selectedFromMap={selectedFromMap}
              eventTicketLimit={eventTicketLimit}
            />
          ) : (
            <TicketDetails
              toggleView={toggleView}
              selectedObject={selectedObject}
              eventTicketLimit={eventTicketLimit}
              changeQuickpickQuantity={undefined}
            />
          )}
        </div>
        <SheetBottom
          totalTicketsPrice={totalPrice}
          selectedTicketCount={totalCount}
          checkout={() => checkout(false)} // is quickpick
          checkingAvailability={checkingAvailability}
        />
      </div>
    </div>
  );
}
